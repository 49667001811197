body{
	padding: 0;
	margin: 0;
    font-family: 'Montserrat', sans-serif;
}
input:focus, a:focus, button:focus{
    outline: none;
}
.banner-section{
    background-image: url(assets/images/main-bnr2.png);
    background-size: cover;
        height: 100vh;
        position: relative;
}
.mov-logo img.img-fluid {
    width: 240px;
}
.mov-sign-in button {
    background: transparent;
    border: 2px solid white;
    padding: 9px 25px;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 50px;
    float: right;
    margin: 7px 0px;
}
.mov-sign-in button:hover{
    background: #fff;
    border: 2px solid #38A1F6;
    color: #38A1F6;
    transition: 0.5s;
}
.banner-text {
    padding: 110px 0px 80px 0px;
}
.banner-text h2 {
    color: #38A1F6;
    font-size: 32px;
    font-weight: 600;
    margin-bottom: 20px;
}
.banner-text p {
    color: #fff;
    font-size: 24px;
    filter: drop-shadow(0px 7px 6px #CFD1C6);
}
.mov-logo, .mov-sign-in {
    padding: 30px 0px 15px 0px;
    overflow: hidden;
}
.arrow-down {
    display: inline-block;
    background: #ffffff52;
    height: 60px;
    width: 60px;
    padding: 19px 10px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: 12px;
    text-align: center;
    cursor: pointer;
    position: absolute;
    bottom: 2%;
    right: 3%;
}
.arrow-down:hover{
    background: #fff;
    transition: 0.5s;
}
.arrow-down img.img-fluid {
    width: 28px;
}
section.one-kit {
    background: #f2f2f285;
    padding: 70px 0px 50px 0px;
}
.one-kit-text h4 {
    color: #38A1F6;
    font-size: 30px;
    margin-bottom: 24px;
}
.one-kit-text p {
    color: #38A1F6;
    font-size: 20px;
    line-height: 32px;
    padding-right: 0px;
}
.one-kit-text {
    padding: 50px 0px;
}
.one-kit-img {
    text-align: center;
}
.points-section h3 {
    color: #38A1F6;
    font-size: 30px;
    margin-bottom: 30px;
}
section.points-section {
    padding: 50px 0px 50px 0px;
}
.points-section p {
    color: #38A1F6;
    font-size: 20px;
}
.points-section p img {
    width: 22px;
    vertical-align: text-bottom;
    margin-right: 6px;
}
section.simplify-txt {
    background: #F2F2F2;
    padding: 30px 0px 20px 0px;
}
.simplify-txt h2 {
    color: #2884CE;
    font-size: 32px;
    text-align: center;
}
section.coming-soon-section {
    background: #0089F8;
    padding: 40px 0px 10px 0px;
}
.coming-soon-txt {
    text-align: center;
    padding: 90px 80px 90px 80px;
}
.coming-soon-txt h4 {
    color: #fff;
    font-size: 32px;
    margin-bottom: 15px;
}
.coming-soon-txt p {
    color: #fff;
    font-size: 22px;
}
.coming-soon-form {
    background: #EEF0F5;
    padding: 14px;
    border-radius: 10px;
    width: 80%;
    margin: 0 auto;
}
.coming-soon-form form {
    background: #fff;
    box-shadow: 0px 3px 6px #00000029;
    padding: 15px 30px;
    text-align: center;
    border-radius: 10px;
    overflow: hidden;
}
.coming-soon-form form input {
    width: 100%;
    border: 1px solid #878787;
    padding: 11px 17px;
    font-style: italic;
    margin-bottom: 30px;
    border-radius: 10px;
}
.coming-soon-form form p {
    color: #A6A6A7;
    font-size: 22px;
    margin-bottom: 30px;
}
.coming-soon-form form input::placeholder{
    color: #D0D3CA;
}
.coming-soon-form form button {
    background: #00FF08;
    border: 1px solid #00ff08;
    padding: 10px 20px;
    color: #fff;
    font-weight: 600;
    font-size: 18px;
    border-radius: 15px;
}
.coming-soon-form form button:hover{
    background: #fff;
    color: #00FF08;
    border: 1px solid #00FF08;
    transition: 0.5s;
}
p.warning {
    color: #fff;
    text-align: center;
    margin-top: 31px;
    font-style: italic;
    margin-bottom: 8px;
    font-size: 12px;
}
.footer-area p {
    color: #878787;
    text-align: center;
    margin: 10px 0px;
    font-size: 12px;
}
.footer-area p img {
    width: 12px;
    margin-right: 5px;
    vertical-align: sub;
}
.footer-area p a{
   text-decoration: underline; 
   color: #878787;
}
section.footer-area {
    padding: 10px 0px 10px 0px;
}
.key-points-img {
    text-align: center;
}
.key-points-txt {
    padding: 20px 0px;
}


/* responsive */

@media (max-width: 720px){
  .mov-logo, .mov-sign-in {
          padding: 20px 0px 10px 0px;
      text-align: center;
  }
  .mov-sign-in button {
      float: none;
  }
  .banner-text {
      padding: 90px 0px 110px 0px;
  }
  .one-kit-text p {
      padding-right: 0px;
  }
  .coming-soon-txt {
      text-align: center;
      padding: 40px 10px 30px 10px;
  }
  .coming-soon-form {
      width: 100%;
  }
  p.warning {
      color: #fff;
      text-align: center;
      margin-top: 30px;
      font-style: italic;
      margin-bottom: 8px;
      padding: 0px 15px;
  }
  }
  @media (min-width: 721px) and (max-width: 1000px){
  .banner-text {
      padding: 60px 0px 10px 0px;
  }
  .one-kit-text p {
      color: #38A1F6;
      font-size: 20px;
      line-height: 30px;
      padding-right: 0px;
  }
  .coming-soon-form {
      width: 100%;
  }
  .coming-soon-txt {
      text-align: center;
      padding: 40px 30px 30px 30px;
  }
  }
  @media (max-width: 340px){
  
  }
  @media (min-width:1001px) and (max-width: 1200px){
  .banner-text {
      padding: 120px 0px 110px 0px;
  }
  }
  @media (min-width: 1400px) and (max-width: 2700px){
   .banner-text {
      padding: 250px 0px 80px 0px;
  }   
  }
  